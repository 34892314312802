<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        {{ user.name }} ({{user.username}})
                        <v-spacer></v-spacer>
                        <v-btn icon x-small>
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon x-small style="margin-left: 10px;">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <ul>
                            <li><b>Utolsó bejelentkezés:</b> {{user.last_login_at}} ({{user.last_login_ip}})</li>
                            <li><b>Jelenlegi bejelentkezés:</b> {{user.current_login_at}} ({{user.current_login_ip}})
                            </li>
                        </ul>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { AuthAPI } from '../../api/auth';

export default {
    name: "auth.viewUser",
    data: () => ({
        user: {},
        loading: true
    }),
    mounted: function () {
        this.$store.commit("app/setTitle", "Felhasnzáló");
        AuthAPI.getUser(this.$route.params.id).then((response) => {
            this.user = response.data;
        }).finally(() => this.loading = false);
    }
};
</script>